//vue instance
import Vue from 'vue'

export const Modals = new Vue({
    data: {
        my_account: false,
        delete_worksheet: false,
        resetPassword: {
            is_visible: false,
            token: '',
            email: '',
        },
        logIn: {
            active_tab: 'sign_up',
            is_visible: false,
        },
        printable: false,
        makeSuggestion: false,
        paymentsRefunds: false,
        privacy: false,
        terms: false,
        checkout: false,
        ratingAlert: false,
        worksheetType: false,
        filename: false,
        error_handling: false,
    },
    methods: {
        close(key) {
            return new Promise((resolve, reject) => {
                if (key == 'logIn') {
                    this.logIn.active_tab = 'sign_up'
                    this.logIn.is_visible = false
                } else if (key == 'resetPassword') {
                    this.resetPassword.token = ''
                    this.resetPassword.email = ''
                    this.resetPassword.is_visible = false
                } else {
                    if (typeof this[key] != 'undefined') {
                        this[key] = false
                    } else {
                        reject(Error('Invalid modal'))
                    }
                }
                resolve(true)
            })
        },
        open(key) {
            return new Promise((resolve, reject) => {
                if (key == 'logIn') {
                    this.logIn.is_visible = true
                } else if (key == 'resetPassword') {
                    this.resetPassword.is_visible = false
                } else {
                    if (typeof this[key] != 'undefined') {
                        this[key] = true
                    } else {
                        reject(Error('Invalid modal'))
                    }
                }
                resolve(true)
            })
        },
    },
})
